/** */
import { combineReducers } from 'redux';
import audiences from './reducers/audience';
import audience_contacts from './reducers/audience-contacts';
import automation from './reducers/automation';
import campaigns from './reducers/campaign';
import contact_fields from './reducers/contact-fields';
import contacts from './reducers/contact';
import flags from './reducers/flag';
import events from './reducers/event';
import header from './reducers/header';
import metadata from './reducers/metadata';
import neurons from './reducers/neuron';
import payment_balance from './reducers/payment';
import plans from './reducers/plan';
import processes from './reducers/process';
import roles from './reducers/role';
import transactionals from './reducers/transactional';
import tutorials from './reducers/tutorial';
import user_data from './reducers/user-data';
import settings from './reducers/settings';
import steps from './reducers/step';
import wallet from './reducers/wallet';
import { LOGOUT } from './actions/user-data';

const appReducer = combineReducers({
  audiences,
  audience_contacts,
  automation,
  campaigns,
  contacts,
  events,
  flags,
  header,
  metadata,
  neurons,
  plans,
  processes,
  roles,
  transactionals,
  tutorials,
  user_data,
  contact_fields,
  payment_balance,
  settings,
  steps,
  wallet
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
